import ApiService from "./api.service";

const startDate = new Date("1 Jan 1990").getTime();

const ENERGY = {
  Energy: 0,
  Electric: 0,
  Gas: 1,
  Solar: 2,
};

const EnergyService = {
  fakeXValue(baseValue, i) {
    return baseValue + 86400000 * i;
  },
  formatXValue(value) {
    if (!value) return null;
    return new Date(value.year, value.month - 1, value.day).getTime();
  },

  processEnergySeries(response, max = -1) {
    let data = [];
    for (let i = 0; i < max; i++) {
      if (i >= response.x.length) {
        data.push([this.fakeXValue(startDate, i), null, null]);
      } else {
        data.push([
          this.fakeXValue(startDate, i),
          response.y[i] ? response.y[i] : null,
          this.formatXValue(response.x[i]),
        ]);
      }
    }
    return data;
  },

  processEnergyResponse(response, period) {
    if (typeof response.data === "string") {
      response.data = JSON.parse(response.data);
    }
    
    if (period == "day") {
      let data = [];
      for (var i = 0; i < response.data.x.length; i++) {
        data.push([response.data.x[i], response.data.y[i]]);
      }
      return data;
    }
    let max = Math.max(
      response.data[0].x.length,
      response.data[1].x.length,
      response.data[2].x.length
    );
    let yCurrent = this.processEnergySeries(response.data[0], max);
    let yLast = this.processEnergySeries(response.data[1], max);
    let yYear = this.processEnergySeries(response.data[2], max);

    return {
      chartData: {
        current: yCurrent,
        last: yLast,
        year: yYear,
      },
      events: {
        current: response.data[0].events,
        last: response.data[1].events,
        year: response.data[2].events,
      },
    };
  },

  // TODO: check if needed and delete
  processGasResponse(response) {
    let yCurrent = this.processEnergySeries(response.data[0]);
    let yYear = this.processEnergySeries(response.data[1]);

    return {
      chartData: {
        current: yCurrent,
        year: yYear,
      },
      events: {
        current: response.data[0].events,
        year: response.data[1].events,
      },
    };
  },

  getConsumptionChartData(date, cui, group) {
    let period = this.getPeriodFromDate(date);

    return new Promise((resolve, reject) => {
      ApiService.post("/consumption/energy", {
        period,
        cui: cui,
        group: group,
        date,
      })
        .then((response) => {
          if (period == "month" || period == "day") {
            resolve(this.processEnergyResponse(response, period));
          } else {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadFileOnboard(formData) {
    return new Promise((resolve, reject) => {
      ApiService.postFormData("/onboard/import", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadFile(type, formData) {
    if (type == "gas" || type == "energy") {
      return new Promise((resolve, reject) => {
        ApiService.postFormData("/import", formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  getGasConsumptionChartData(date, cui, group) {
    let period = this.getPeriodFromDate(date);
    return new Promise((resolve, reject) => {
      ApiService.post("/consumption/gas", {
        period,
        date,
        cui: cui,
        group: group,
      })
        .then((response) => {
          if (period == "month" || period == "day") {
            resolve(this.processEnergyResponse(response, period));
          } else {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPeriodFromDate(date) {
    if (date.day.length != 0) {
      return "day";
    }
    if (date.month.length != 0) {
      return "month";
    }
    return "year";
  },

  getGenerationChartData(id, date) {
    let period = this.getPeriodFromDate(date);
    return new Promise((resolve, reject) => {
      ApiService.post("/solar/production", {
        id,
        period,
        date,
      })
        .then((response) => {
          resolve(this.processEnergyResponse(response, period));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getSolarRadiationChartData(id, period) {
    return new Promise((resolve, reject) => {
      ApiService.post("/solar/radiation", {
        id,
        period,
      })
        .then((response) => {
          let data = {
            chartData: this.processEnergySeries(response.data),
            events: response.data.events,
          };
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getOnboardInfo(cui, name) {
    return new Promise((resolve, reject) => {
      ApiService.post("/onboard/details", {
        cui: cui,
        name: name,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getOnboardPrediction(data) {
    data.type = ENERGY[data.type];
    return new Promise((resolve, reject) => {
      ApiService.post("/onboard/predict", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getIndividualCompanies(offset, limit, type, search) {
    type = type.toLowerCase();
    if (type == "energy") type = "electric";
    return new Promise((resolve, reject) => {
      ApiService.post("/companies", { offset, limit, type, search })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCompanyGroups(offset, limit, type, search) {
    return new Promise((resolve, reject) => {
      ApiService.post("/groups", { offset, limit, type, search })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getImportData(type) {
    return new Promise((resolve, reject) => {
      ApiService.post("/import-history", { type })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  undoImport(type, id) {
    return new Promise((resolve, reject) => {
      ApiService.post("/import-undo", { type, id })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAuthenticatedUserInfo() {
    return new Promise((resolve, reject) => {
      ApiService.get("/auth/user")
        .then((response) => {
          let result = {
            name: response.data.first_name ? response.data.first_name : " ",
            surname: response.data.last_name ? response.data.last_name : " ",
            username: response.data.username ? response.data.username : " ",
            email: response.data.email ? response.data.email : " ",
          };
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateUser(data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/update-user", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadCsvDayFile(data) {
    return new Promise((resolve, reject) => {
      ApiService.download("/export/dashboard/day", data)
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          let fileName = this.getFilenameFromHeader(res);
          docUrl.setAttribute("download", fileName ? fileName : "export.csv");
          document.body.appendChild(docUrl);
          docUrl.click();

          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadCsvFile(data) {
    return new Promise((resolve, reject) => {
      ApiService.download("/export/dashboard", data)
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          let fileName = this.getFilenameFromHeader(res);
          docUrl.setAttribute("download", fileName ? fileName : "export.csv");
          document.body.appendChild(docUrl);
          docUrl.click();

          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadCsvAllMonthFile(data) {
    return new Promise((resolve, reject) => {
      ApiService.download("/consumption/month_export_energy", data)
        .then((response) => {
          var FILE = window.URL.createObjectURL(new Blob([response.data]));

          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          let fileName = this.getFilenameFromHeader(response);
          docUrl.setAttribute("download", fileName ? fileName : "report.xlsx");
          document.body.appendChild(docUrl);
          docUrl.click();

          resolve("OK");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadFile(data) {
    this.basicDownload("/import-download", data);
  },

  basicDownload(url, data) {
    ApiService.download(url, data).then((response) => {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");

      fileLink.href = fileURL;
      let fileName = this.getFilenameFromHeader(response);
      fileLink.setAttribute("download", fileName ? fileName : "report.csv");
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  },

  getFilenameFromHeader(response) {
    let filename = "";
    let disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  },

  getGlobalPredictions(type) {
    type = ENERGY[type];
    return new Promise((resolve, reject) => {
      ApiService.post("/predict", { type })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getLanguage() {
    let language = localStorage.getItem("language");
    language = language === "ro" ? "ro-RO" : "en-US";
    return language;
  },

  getDateUTC(value) {
    const date = new Date(value);
    const timeDiff = date.getTimezoneOffset() * 60000;
    let adjustedDate = new Date(date.valueOf() + timeDiff);

    return adjustedDate.getTime();
  },

  processPerformanceYVal(originalValue) {
    if (originalValue === 0) {
      return 0;
    }
    if (!originalValue) {
      return null;
    }
    return originalValue;
  },

  getPerformance(cui, group, type, date) {
    type = ENERGY[type];
    return new Promise((resolve, reject) => {
      ApiService.post("/performance", { cui: cui, group: group, type, date })
        .then((response) => {
          if (typeof response.data === "string") {
            response.data = JSON.parse(response.data);
          }
          response.data.outliers = [];
          for (let i = 0; i < response.data.x.length; i++) {
            let x = date["day"]
              ? this.getDateUTC(response.data.x[i])
              : this.formatXValue(response.data.x[i]);
            let yReal = this.processPerformanceYVal(response.data.y_real[i]);
            let yTop = this.processPerformanceYVal(response.data.y_top[i]);
            let yBottom = this.processPerformanceYVal(response.data.y_bottom[i]);
            let yPredicted = this.processPerformanceYVal(response.data.y_predicted[i]);

            response.data.y_real[i] = [x, yReal];
            response.data.y_top[i] = [x, yTop];
            response.data.y_bottom[i] = [x, yBottom];
            response.data.y_predicted[i] = [x, yPredicted];

            // set outliers
            if (yReal < yBottom || yReal > yTop) {
              response.data.outliers.push([
                new Date(x).getTime(),
                parseFloat(yReal),
                "Outlier",
              ]);
            }
          }
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addGroup(type, cuis, name) {
    type = ENERGY[type];
    return new Promise((resolve, reject) => {
      ApiService.post("/groups/add", { type, cuiList: cuis, name })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editGroup(type, cuis, name, oldName, id) {
    type = ENERGY[type];
    return new Promise((resolve, reject) => {
      ApiService.post("/groups/edit", { type, cuiList: cuis, name, oldName, id })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteGroup(type, item) {
    type = ENERGY[type];
    return new Promise((resolve, reject) => {
      ApiService.post("/groups/delete", { type, ...item })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default EnergyService;
